.dropdown {
  z-index: 10000;
  position: relative;
}
.dropdown,
.dropdown * {
  z-index: 10;
}
.dropdown .dropdown-btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.dropdown-content {
  position: absolute;
  left: 0;
  background: white;
  width: 200px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.dropdown-content .item:hover {
  background: #b38eff;
  color: white;
  font-family: 'Barlow-Regular';
  font-size: 16px;
}
