@import url(react-datepicker/dist/react-datepicker.css);
@import url(animate.css/animate.min.css);
@import url(react-toastify/dist/ReactToastify.min.css);
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@font-face {
  font-family: 'Barlow-Black';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-Bold';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-ExtraBold';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-ExtraLight';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-Light';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-Medium';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-Regular';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-SemiBold';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Barlow-Thin';
  src: local(""), url("~src/assets/fonts/Barlow/Barlow-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }
